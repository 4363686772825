import React, { useState, useRef } from "react";
import {
  Grid,
  Typography,
  TextField,
  Paper,
  Button,
  CircularProgress,
  Divider,
} from "@material-ui/core";

import * as yup from "yup";
import { useFormik } from "formik";

import CustomAlert from "components/CustomAlert";

import axios from "config/axios";

import useStyles from "./styles";

const RegisterSchema = yup.object().shape({
  question: yup
    .string()
    .min(2, "Muito curta")
    .required("A pergunta é obrigatória"),
  rightAnswer: yup.string().required("A resposta correta é obrigatória"),
  wrong1: yup.string().required("A resposta errada 1 é obrigatória"),
  wrong2: yup.string().required("A resposta errada 2 obrigatória"),
  wrong3: yup.string().required("A resposta errada 3 obrigatória"),
  category: yup.string(),
});

const UserLoginSchema = yup.object().shape({
  login: yup.string().required("Entre com o login passado"),
  password: yup.string().required("Entre com a sua senha"),
});

interface UserLogin {
  login: string;
  password: string;
}

interface Values {
  question: string;
  rightAnswer: string;
  wrong1: string;
  wrong2: string;
  wrong3: string;
  category: string;
}

const Main: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [gameId, setGameId] = useState("");
  const [showLogin, setShowLogin] = useState(true);
  const classes = useStyles();

  const alertRef = useRef<any>();

  const formik = useFormik({
    initialValues: {
      question: "",
      rightAnswer: "",
      wrong1: "",
      wrong2: "",
      wrong3: "",
      category: "any",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });

  const login = async (values: UserLogin) => {
    setLoading((oldState) => !oldState);
    axios
      .post("/auth/trivia/login", {
        login: values.login,
        password: values.password,
      })
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        formikUserLogin.resetForm();
        setShowLogin(false);
        setGameId(data.game);
        alertRef?.current.alterAlert("Bem vindo!", "success");
      })
      .catch((error) => {
        console.log(error);
        setLoading((oldState) => !oldState);
        switch (error?.response?.data?.status) {
          case 404:
            alertRef?.current.alterAlert("Usuário não encontrado", "warning");
            break;
          case 401:
            alertRef?.current.alterAlert("Senha inválida", "warning");
            break;
          default:
            alertRef?.current.alterAlert("Problema ao entrar", "error");
            break;
        }
      });
  };

  const formikUserLogin = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema: UserLoginSchema,
    onSubmit: (values) => {
      login(values);
    },
  });

  const submit = async (values: Values) => {
    const { question, rightAnswer, wrong1, wrong2, wrong3, category } = values;
    setLoading((oldState) => !oldState);
    axios
      .post("/trivia", {
        question,
        rightAnswer,
        wrongAnswers: [wrong1, wrong2, wrong3],
        game: gameId,
        category,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        formik.resetForm();
        alertRef?.current.alterAlert("Pergunta cadastrada!", "success");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          case 404:
            alertRef?.current.alterAlert("Jogo não encontrado", "warning");
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao cadastrar pergunta",
              "error"
            );
            break;
        }
      });
  };

  if (loading) {
    return (
      <div className={classes.root}>
        <Paper className={classes.paper} style={{ textAlign: "center" }}>
          <CircularProgress size={50} />
        </Paper>
      </div>
    );
  }

  if (showLogin) {
    return (
      <>
        <CustomAlert ref={alertRef} />
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <TextField
                  fullWidth
                  placeholder="Login"
                  type="text"
                  name="login"
                  value={formikUserLogin.values.login}
                  onChange={formikUserLogin.handleChange}
                  error={
                    formikUserLogin.touched.login &&
                    Boolean(formikUserLogin.errors.login)
                  }
                  helperText={
                    formikUserLogin.touched.login &&
                    formikUserLogin.errors.login
                  }
                  inputProps={{ style: { padding: 13 } }}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  placeholder="Senha"
                  type="password"
                  name="password"
                  value={formikUserLogin.values.password}
                  onChange={formikUserLogin.handleChange}
                  error={
                    formikUserLogin.touched.password &&
                    Boolean(formikUserLogin.errors.password)
                  }
                  helperText={
                    formikUserLogin.touched.password &&
                    formikUserLogin.errors.password
                  }
                  inputProps={{ style: { padding: 13 } }}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => formikUserLogin.handleSubmit()}
                  variant="contained"
                  color="primary"
                >
                  Entrar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </>
    );
  }
  return (
    <>
      <CustomAlert ref={alertRef} />
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h1" className={classes.title} align="center">
            Cadastro de perguntas
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Pergunta"
                  type="text"
                  name="question"
                  required
                  value={formik.values.question}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.question && Boolean(formik.errors.question)
                  }
                  helperText={formik.touched.question && formik.errors.question}
                  inputProps={{ style: { padding: 13 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Resposta correta"
                  type="text"
                  name="rightAnswer"
                  required
                  value={formik.values.rightAnswer}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.rightAnswer &&
                    Boolean(formik.errors.rightAnswer)
                  }
                  helperText={
                    formik.touched.rightAnswer && formik.errors.rightAnswer
                  }
                  inputProps={{ style: { padding: 13 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <br />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Resposta errada 1"
                  type="text"
                  name="wrong1"
                  required
                  value={formik.values.wrong1}
                  onChange={formik.handleChange}
                  error={formik.touched.wrong1 && Boolean(formik.errors.wrong1)}
                  helperText={formik.touched.wrong1 && formik.errors.wrong1}
                  inputProps={{ style: { padding: 13 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Resposta errada 2"
                  type="text"
                  name="wrong2"
                  required
                  value={formik.values.wrong2}
                  onChange={formik.handleChange}
                  error={formik.touched.wrong2 && Boolean(formik.errors.wrong2)}
                  helperText={formik.touched.wrong2 && formik.errors.wrong2}
                  inputProps={{ style: { padding: 13 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Resposta errada 3"
                  type="text"
                  name="wrong3"
                  required
                  value={formik.values.wrong3}
                  onChange={formik.handleChange}
                  error={formik.touched.wrong3 && Boolean(formik.errors.wrong3)}
                  helperText={formik.touched.wrong3 && formik.errors.wrong3}
                  inputProps={{ style: { padding: 13 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Category"
                  type="text"
                  name="category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                  required={false}
                  helperText={formik.touched.category && formik.errors.category}
                  inputProps={{ style: { padding: 13 } }}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => formik.handleSubmit()}
                  variant="contained"
                  color="primary"
                >
                  Cadastrar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default Main;
